module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@knowit-jamstack/gatsby-theme-knowit/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["GalanoGrotesqueAlt-Bold","GalanoGrotesqueAlt-Light","GalanoGrotesqueAlt-Medium","GalanoGrotesqueAlt-Regular"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"alma-talent-frontend","short_name":"Alma Insights","start_url":"/","background_color":"#000000","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"96d5cccee9df2d518107220416330b7c"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"searchAndReplace":[{"search":"https://almatalentfi-develop.netlify.app","replace":"https://staging.almatalent.fi"},{"search":"https://almatalentfi.netlify.app","replace":"https://www.almainsights.fi"}],"url":"https://almatalent.wpengine.com/graphql/","verbose":true,"schema":{"timeout":960000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"Page":{"limit":10000},"Post":{"limit":10000},"Tag":{},"Taxonomy":{},"MenuItem":{},"Menu":{},"Client":{},"Comment":{},"PostFormat":{},"UserRole":{},"User":{},"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4","image/jpeg","image/png","image/gif","image/bmp","application/pdf"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WJJJGZ2","includeInDevelopment":false,"routeChangeEventName":"virtualpageview","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
